.right-box[data-v-ac6176be] {
  background: #fff;
  border-radius: 4px;
}
.right-box .cap-wrapper[data-v-ac6176be] {
  text-align: center;
}
.right-box[data-v-ac6176be] .el-scrollbar__view {
  padding: 15px;
}
.right-box .select-item[data-v-ac6176be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px dashed #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.right-box .select-item .close-btn[data-v-ac6176be] {
  cursor: pointer;
  color: #f56c6c;
}
.right-box .select-item .el-select[data-v-ac6176be] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.right-box .select-line-icon[data-v-ac6176be] {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #606266;
}
.right-box .dataBoard-item .el-form-item--small.el-form-item[data-v-ac6176be] {
  margin-bottom: 10px;
}
.right-box .dataBoard-item .el-divider--horizontal[data-v-ac6176be] {
  margin: 14px 0;
}
.right-box .dataBoard-item .head[data-v-ac6176be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.right-box .dataBoard-item .head .head-icon[data-v-ac6176be] {
  color: #f56c6c;
}