.SNOWS-Portal[data-v-56902129] {
  height: 100%;
}
.SNOWS-Portal .el-aside[data-v-56902129],
.SNOWS-Portal[data-v-56902129] .el-aside {
  padding: 0;
  margin: 0;
}
.SNOWS-Portal .el-aside .cap-wrapper[data-v-56902129],
.SNOWS-Portal[data-v-56902129] .el-aside .cap-wrapper {
  position: relative;
  height: 42px;
  border-bottom: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 41px;
  padding-left: 10px;
  font-size: 16px;
}
.SNOWS-Portal .el-aside .aside-scrollbar[data-v-56902129],
.SNOWS-Portal[data-v-56902129] .el-aside .aside-scrollbar {
  height: calc(100% - 42px);
  overflow: hidden;
}
.SNOWS-Portal .el-aside .aside-scrollbar .el-scrollbar__wrap[data-v-56902129],
.SNOWS-Portal[data-v-56902129] .el-aside .aside-scrollbar .el-scrollbar__wrap {
  overflow-x: auto;
}
.SNOWS-Portal .left-box[data-v-56902129] {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.SNOWS-Portal .left-box .components-list[data-v-56902129] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
}
.SNOWS-Portal .left-box .components-list .components-title[data-v-56902129] {
  font-size: 14px;
  color: #043254;
  line-height: 42px;
  margin-bottom: 10px;
  font-weight: bold;
  padding-left: 10px;
  border-bottom: 1px solid #dcdfe6;
}
.SNOWS-Portal .left-box .components-list .components-part[data-v-56902129] {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 0;
}
.SNOWS-Portal .left-box .components-list .components-part .list[data-v-56902129] {
  margin-right: -10px;
  padding: 0 10px 0;
}
.SNOWS-Portal .left-box .components-list .components-part[data-v-56902129]:last-child {
  margin-bottom: 0;
}
.SNOWS-Portal .left-box .components-list .components-item[data-v-56902129] {
  display: inline-block;
  width: 110px;
  margin: 0 10px 10px 0;
}
.SNOWS-Portal .left-box .components-list .components-item .components-body[data-v-56902129] {
  padding-left: 8px;
  background: #f3f9ff;
  font-size: 12px;
  cursor: move;
  border: 1px dashed #f3f9ff;
  border-radius: 3px;
  color: #043254;
  line-height: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.SNOWS-Portal .left-box .components-list .components-item .components-body i[data-v-56902129] {
  color: #043254;
  line-height: 16px;
  height: 16px;
  margin-right: 4px;
}
.SNOWS-Portal .el-main[data-v-56902129] {
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.SNOWS-Portal .el-main .action-bar[data-v-56902129] {
  position: relative;
  height: 42px;
  text-align: right;
  padding: 0 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  margin: 0 10px;
  border: none;
}
.SNOWS-Portal .el-main .action-bar .delete-btn[data-v-56902129] {
  color: #f56c6c !important;
}
.SNOWS-Portal .el-main .empty-info[data-v-56902129] {
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
}
.SNOWS-Portal .el-main .empty-info .empty-img[data-v-56902129] {
  width: 500px;
  height: 500px;
}
.SNOWS-Portal .el-main[data-v-56902129] .vue-grid-item.vue-grid-placeholder {
  background: #409eff;
}
.SNOWS-Portal .el-main .layout-area[data-v-56902129] {
  height: calc(100% - 42px);
}
.SNOWS-Portal .el-main .layout-area[data-v-56902129] .el-scrollbar__wrap {
  margin-bottom: 0 !important;
  overflow-x: auto;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item[data-v-56902129] {
  position: relative;
  height: 100%;
  border: 1px dashed #e2e0e0;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item.active-item[data-v-56902129] {
  border: 1px solid #409eff;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item.active-item .drawing-item-copy[data-v-56902129],
.SNOWS-Portal .el-main .layout-area .vue-grid-item.active-item .drawing-item-delete[data-v-56902129] {
  display: block;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item[data-v-56902129] .vue-resizable-handle {
  z-index: 20;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item .mask[data-v-56902129] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item .drawing-item-copy[data-v-56902129],
.SNOWS-Portal .el-main .layout-area .vue-grid-item .drawing-item-delete[data-v-56902129] {
  display: none;
  position: absolute;
  top: -10px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px solid;
  cursor: pointer;
  z-index: 20;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item .drawing-item-copy[data-v-56902129] {
  right: 56px;
  border-color: #409eff;
  color: #409eff;
  background: #fff;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item .drawing-item-copy[data-v-56902129]:hover {
  background: #409eff;
  color: #fff;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item .drawing-item-delete[data-v-56902129] {
  right: 20px;
  border-color: #f56c6c;
  color: #f56c6c;
  background: #fff;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item .drawing-item-delete[data-v-56902129]:hover {
  background: #f56c6c;
  color: #fff;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item:hover .drawing-item-copy[data-v-56902129],
.SNOWS-Portal .el-main .layout-area .vue-grid-item:hover .drawing-item-delete[data-v-56902129] {
  display: block;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item[data-v-56902129] .el-card {
  width: 100%;
  height: 100%;
  border: none;
}
.SNOWS-Portal .el-main .layout-area .vue-grid-item[data-v-56902129] .el-card .el-card__body {
  padding: 0;
}